<template>
  <div>
    <search-product @submit="submitSearch" />
    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :scroll="{ x: 'max-content' }"
      :pagination="false"
      @change="sortChange"
      row-key="id"
    >
      <div slot="name" slot-scope="name, record" v-if="isShowMenu('customer/agents_products/show')">
        <a @click="showInfoModal(record.id)">{{ name }}</a>
      </div>

      <div slot="retail_price" slot-scope="retail_price">
        <span>{{ formatFloatDisplay(retail_price, "retail_price") }}</span>
      </div>

      <span slot="actions" slot-scope="text, record">
        <a-dropdown>
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            操作 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-if="isHasEditPermission">
              <a @click="showEditModal(record.id)">编辑</a>
            </a-menu-item>
            <a-menu-item>
              <a @click="showOperationHistoryModal(record.id)">操作历史</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 编辑模态框 -->
    <edit-agents-product
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :id="editingId"
      @completed="fetchData"
    />

    <!-- 详情模态框 -->
    <show-agents-product
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
    />

    <operation-history-list-modal
      v-if="isShowOperationHistoryModal"
      :reference-id="showingOperationHistoryId"
      reference-type="AgentsProduct"
      :visible.sync="isShowOperationHistoryModal"
    />
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { findAgentsProducts } from '@/api/agents_product'
import SearchProduct from '@/views/agents_products/Search'
import Pagination from '@/components/Pagination'
import { formatFloat } from '@/utils/filter'

export default {
  name: 'AgentsProductList',
  components: {
    Pagination,
    SearchProduct,
    EditAgentsProduct: () => import('@/views/agents_products/Edit'),
    ShowAgentsProduct: () => import('@/views/agents_products/Show'),
    OperationHistoryListModal: () => import('@/views/operation_histories/Modal')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowEditModal: false, // 是否显示编辑表单弹窗
      isShowInfoModal: false, // 是否显示详情弹窗
      editingId: 0, // 正在编辑的记录id
      showingId: 0,
      isShowOperationHistoryModal: false,
      showingOperationHistoryId: 0,
      pagination: {
        total_count: 0
      },
      query: {
        effective: '1',
        per_page: 10
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    agentId() {
      if (this.$route.query.agent_id) {
        return parseInt(this.$route.query.agent_id)
      }

      return this.$store.getters.userAgentId
    },

    agentType() {
      return parseInt(this.$store.getters.userAgentType)
    },

    columns() {
      var columnArr = [
        {
          title: '套餐编号',
          dataIndex: 'code',
          fixed: 'left'
        },
        {
          title: '套餐名称',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          fixed: 'left'
        },
        {
          title: '套餐类型',
          dataIndex: 'product_type'
        },
        {
          title: '运营商种类',
          dataIndex: 'carrier_type'
        },
        {
          title: '用户业务模式',
          dataIndex: 'user_business_type'
        },
        {
          title: '是否可超池',
          dataIndex: 'is_allow_exceed_pool'
        },
        {
          title: '套餐周期',
          dataIndex: 'service_cycle'
        },
        {
          title: '套餐容量',
          dataIndex: 'package_capacity'
        },
        {
          title: '语音',
          dataIndex: 'voice_capacity'
        },
        {
          title: '套餐价格(元)',
          dataIndex: 'distributor_price',
          customRender: formatFloat
        },
        {
          title: '套餐有效性',
          dataIndex: 'effective'
        },
        {
          title: '操作',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' },
          fixed: 'right'
        }
      ]

      if (this.agentType === 1) {
        columnArr.splice(8, 0, {
          title: '零售价格(元)',
          dataIndex: 'retail_price',
          scopedSlots: { customRender: 'retail_price' }
        })
      }

      return columnArr
    },

    isHasEditPermission() {
      return hasPermission('customer/agents_products/edit')
    }
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showEditModal(recordId) {
      this.editingId = recordId
      this.isShowEditModal = true
    },

    showInfoModal(recordId) {
      this.showingId = recordId
      this.isShowInfoModal = true
    },

    // 显示操作历史
    showOperationHistoryModal(id) {
      this.showingOperationHistoryId = id
      this.isShowOperationHistoryModal = true
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    formatFloatDisplay(value) {
      if (value === -1) {
        return '-'
      }

      return formatFloat(value)
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findAgentsProducts(
        Object.assign({ agent_id: this.agentId }, this.query, this.sort)
      ).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    }
  }
}
</script>
